@use 'styles/colors.module';
@use 'styles/typography';
@use 'styles/mixins';

.container {
  display: grid;
  grid-template-columns: 1fr;

  @include mixins.from-md-up {
    grid-template-columns: repeat(2, 1fr);
  }
}

.title {
  @include typography.header-4;

  text-align: center;
}

.content {
  @include typography.body-2;

  padding: 20px 0;
  text-align: center;
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @include mixins.from-md-up {
    justify-content: center;
  }
}

.section-container {
  display: flex;
}

.image {
  display: flex;
  place-self: center;
  order: 1;

  @include mixins.from-md-up {
    order: 0;
  }
}
