$icomoon-font-family: "Boto-Icons" !default;
$icomoon-font-path: "fonts" !default;
$bti-align-justfy: "\e903";
$bti-arrow-left: "\e946";
$bti-arrow-right: "\e947";
$bti-arrow-right-left: "\e904";
$bti-arrow-top-right: "\e948";
$bti-asaas-logo: "\e905";
$bti-award: "\e906";
$bti-bank: "\e907";
$bti-bar-chart: "\e908";
$bti-bar-code: "\e909";
$bti-base: "\e94a";
$bti-bell: "\e90a";
$bti-box: "\e955";
$bti-calendar: "\e90b";
$bti-card: "\e90c";
$bti-cash-machine-with-ticket: "\e90d";
$bti-chart-line: "\e90e";
$bti-check: "\e90f";
$bti-check-circle: "\e910";
$bti-check-dialog: "\e951";
$bti-chevron-down: "\e942";
$bti-chevron-left: "\e943";
$bti-chevron-right: "\e944";
$bti-chevron-up: "\e945";
$bti-client: "\e94c";
$bti-clock: "\e911";
$bti-cloud: "\e912";
$bti-cog: "\e913";
$bti-companies: "\e94e";
$bti-copy: "\e914";
$bti-discord: "\e949";
$bti-dolar-zap: "\e915";
$bti-dollar-sign: "\e916";
$bti-download: "\e917";
$bti-email: "\e900";
$bti-envelope: "\e918";
$bti-facebook: "\e919";
$bti-file-dolar: "\e91a";
$bti-file-import-solid: "\e91b";
$bti-file-plus: "\e954";
$bti-file-text: "\e91c";
$bti-headset: "\e91d";
$bti-image: "\e91e";
$bti-info: "\e91f";
$bti-instagram: "\e920";
$bti-key: "\e950";
$bti-link: "\e921";
$bti-linkedin: "\e922";
$bti-loader: "\e901";
$bti-math-signs: "\e93a";
$bti-message-circle-dollar: "\e923";
$bti-message-circles: "\e924";
$bti-message-sms: "\e902";
$bti-minus: "\e925";
$bti-money: "\e926";
$bti-money-hand: "\e927";
$bti-null: "\e928";
$bti-phone: "\e929";
$bti-pix: "\e92a";
$bti-play: "\e92b";
$bti-plus: "\e92c";
$bti-qr-code: "\e94f";
$bti-question: "\e953";
$bti-quotation-marks: "\e92d";
$bti-reverse-clock: "\e92e";
$bti-rotate-cw: "\e92f";
$bti-search: "\e930";
$bti-serasa: "\e931";
$bti-shield: "\e932";
$bti-shopping-bag: "\e933";
$bti-small-business: "\e94d";
$bti-smartphone: "\e934";
$bti-smile-face: "\e935";
$bti-sort: "\e936";
$bti-sort-amount-down: "\e937";
$bti-split: "\e938";
$bti-spotify: "\e939";
$bti-target: "\e93b";
$bti-tiktok: "\e952";
$bti-transaction-receipt: "\e93c";
$bti-trash: "\e94b";
$bti-twitter: "\e93d";
$bti-whatsapp: "\e93e";
$bti-x: "\e93f";
$bti-youtube: "\e940";
$bti-zero: "\e941";
