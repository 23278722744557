/* stylelint-disable scss/at-rule-no-unknown */
@use "colors.module";
@use "mixins";
@use "typography";
@import "fonts";
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-size: 10px;
  line-height: 1.25;
}

* {
  box-sizing: border-box;
  font-stretch: normal;
  font-style: normal;
  margin: 0;
  outline: none;
  padding: 0;
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
  text-size-adjust: 100%;
}

body {
  @include typography.body-1;

  color: colors.$neutral-low-dark;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  text-rendering: optimizelegibility;
}

.content-margin {
  margin: 0 auto;
  width: 91vw;
}

@include mixins.from-sm-up {
  .content-margin {
    width: 80vw;
  }
}

.default-link {
  color: colors.$global-primary-500;
  text-decoration: underline;
}
