.section {
  padding: 10vh 0;
}

.full-height {
  min-height: 100vh;
}

.no-padding {
  padding: 0;
}
