@use "colors.module";
@use "mixins";

.slick-dots-custom {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 1rem 0;

  @include mixins.from-md-up {
    justify-content: left;
  }

  li {
    margin: 0 8px;
  }

  button {
    background-color: colors.$neutral-high-pure;
    border: 2px solid colors.$global-primary-500;
    border-radius: 100%;
    display: block;
    height: 10px;
    padding: 0;
    text-indent: -9999px;
    width: 10px;
  }

  li.slick-active button {
    background-color: colors.$global-primary-500;
  }
}

.slick-dots-custom-milestones {
  align-items: center;
  display: flex;
  justify-content: center;

  /* stylelint-disable-next-line no-descending-specificity */
  button {
    background-color: colors.$global-primary-500;
    border-color: colors.$neutral-high-pure;
  }

  li.slick-active button {
    background-color: colors.$neutral-high-pure;
  }
}

.slick-dots-custom-media {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 2.4rem 0 0;

  /* stylelint-disable-next-line no-descending-specificity */
  button {
    background-color: colors.$neutral-high-pure;
    border-color: colors.$global-primary-500;
  }

  li.slick-active button {
    background-color: colors.$global-primary-500;
  }
}
