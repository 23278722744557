/* stylelint-disable */
@import "variables";

$base-icon-size: 8;

@mixin icon-size($size) {
  font-size: $size * $base-icon-size + px;
}

@for $i from 1 through 6 {
  .bti-#{$i}x {
    @include icon-size($i);
  }
}

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?cygcr6');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?cygcr6#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?cygcr6') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?cygcr6') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?cygcr6##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.boto-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bti-align-justfy {
  &:before {
    content: $bti-align-justfy;
  }
}
.bti-arrow-left {
  &:before {
    content: $bti-arrow-left;
  }
}
.bti-arrow-right {
  &:before {
    content: $bti-arrow-right;
  }
}
.bti-arrow-right-left {
  &:before {
    content: $bti-arrow-right-left;
  }
}
.bti-arrow-top-right {
  &:before {
    content: $bti-arrow-top-right;
  }
}
.bti-asaas-logo {
  &:before {
    content: $bti-asaas-logo;
  }
}
.bti-award {
  &:before {
    content: $bti-award;
  }
}
.bti-bank {
  &:before {
    content: $bti-bank;
  }
}
.bti-bar-chart {
  &:before {
    content: $bti-bar-chart;
  }
}
.bti-bar-code {
  &:before {
    content: $bti-bar-code;
  }
}
.bti-base {
  &:before {
    content: $bti-base;
  }
}
.bti-bell {
  &:before {
    content: $bti-bell;
  }
}
.bti-box {
  &:before {
    content: $bti-box;
  }
}
.bti-calendar {
  &:before {
    content: $bti-calendar;
  }
}
.bti-card {
  &:before {
    content: $bti-card;
  }
}
.bti-cash-machine-with-ticket {
  &:before {
    content: $bti-cash-machine-with-ticket;
  }
}
.bti-chart-line {
  &:before {
    content: $bti-chart-line;
  }
}
.bti-check {
  &:before {
    content: $bti-check;
  }
}
.bti-check-circle {
  &:before {
    content: $bti-check-circle;
  }
}
.bti-check-dialog {
  &:before {
    content: $bti-check-dialog;
  }
}
.bti-chevron-down {
  &:before {
    content: $bti-chevron-down;
  }
}
.bti-chevron-left {
  &:before {
    content: $bti-chevron-left;
  }
}
.bti-chevron-right {
  &:before {
    content: $bti-chevron-right;
  }
}
.bti-chevron-up {
  &:before {
    content: $bti-chevron-up;
  }
}
.bti-client {
  &:before {
    content: $bti-client;
  }
}
.bti-clock {
  &:before {
    content: $bti-clock;
  }
}
.bti-cloud {
  &:before {
    content: $bti-cloud;
  }
}
.bti-cog {
  &:before {
    content: $bti-cog;
  }
}
.bti-companies {
  &:before {
    content: $bti-companies;
  }
}
.bti-copy {
  &:before {
    content: $bti-copy;
  }
}
.bti-discord {
  &:before {
    content: $bti-discord;
  }
}
.bti-dolar-zap {
  &:before {
    content: $bti-dolar-zap;
  }
}
.bti-dollar-sign {
  &:before {
    content: $bti-dollar-sign;
  }
}
.bti-download {
  &:before {
    content: $bti-download;
  }
}
.bti-email {
  &:before {
    content: $bti-email;
  }
}
.bti-envelope {
  &:before {
    content: $bti-envelope;
  }
}
.bti-facebook {
  &:before {
    content: $bti-facebook;
  }
}
.bti-file-dolar {
  &:before {
    content: $bti-file-dolar;
  }
}
.bti-file-import-solid {
  &:before {
    content: $bti-file-import-solid;
  }
}
.bti-file-plus {
  &:before {
    content: $bti-file-plus;
  }
}
.bti-file-text {
  &:before {
    content: $bti-file-text;
  }
}
.bti-headset {
  &:before {
    content: $bti-headset;
  }
}
.bti-image {
  &:before {
    content: $bti-image;
  }
}
.bti-info {
  &:before {
    content: $bti-info;
  }
}
.bti-instagram {
  &:before {
    content: $bti-instagram;
  }
}
.bti-key {
  &:before {
    content: $bti-key;
  }
}
.bti-link {
  &:before {
    content: $bti-link;
  }
}
.bti-linkedin {
  &:before {
    content: $bti-linkedin;
  }
}
.bti-loader {
  &:before {
    content: $bti-loader;
  }
}
.bti-math-signs {
  &:before {
    content: $bti-math-signs;
  }
}
.bti-message-circle-dollar {
  &:before {
    content: $bti-message-circle-dollar;
  }
}
.bti-message-circles {
  &:before {
    content: $bti-message-circles;
  }
}
.bti-message-sms {
  &:before {
    content: $bti-message-sms;
  }
}
.bti-minus {
  &:before {
    content: $bti-minus;
  }
}
.bti-money {
  &:before {
    content: $bti-money;
  }
}
.bti-money-hand {
  &:before {
    content: $bti-money-hand;
  }
}
.bti-null {
  &:before {
    content: $bti-null;
  }
}
.bti-phone {
  &:before {
    content: $bti-phone;
  }
}
.bti-pix {
  &:before {
    content: $bti-pix;
  }
}
.bti-play {
  &:before {
    content: $bti-play;
  }
}
.bti-plus {
  &:before {
    content: $bti-plus;
  }
}
.bti-qr-code {
  &:before {
    content: $bti-qr-code;
  }
}
.bti-question {
  &:before {
    content: $bti-question;
  }
}
.bti-quotation-marks {
  &:before {
    content: $bti-quotation-marks;
  }
}
.bti-reverse-clock {
  &:before {
    content: $bti-reverse-clock;
  }
}
.bti-rotate-cw {
  &:before {
    content: $bti-rotate-cw;
  }
}
.bti-search {
  &:before {
    content: $bti-search;
  }
}
.bti-serasa {
  &:before {
    content: $bti-serasa;
  }
}
.bti-shield {
  &:before {
    content: $bti-shield;
  }
}
.bti-shopping-bag {
  &:before {
    content: $bti-shopping-bag;
  }
}
.bti-small-business {
  &:before {
    content: $bti-small-business;
  }
}
.bti-smartphone {
  &:before {
    content: $bti-smartphone;
  }
}
.bti-smile-face {
  &:before {
    content: $bti-smile-face;
  }
}
.bti-sort {
  &:before {
    content: $bti-sort;
  }
}
.bti-sort-amount-down {
  &:before {
    content: $bti-sort-amount-down;
  }
}
.bti-split {
  &:before {
    content: $bti-split;
  }
}
.bti-spotify {
  &:before {
    content: $bti-spotify;
  }
}
.bti-target {
  &:before {
    content: $bti-target;
  }
}
.bti-tiktok {
  &:before {
    content: $bti-tiktok;
  }
}
.bti-transaction-receipt {
  &:before {
    content: $bti-transaction-receipt;
  }
}
.bti-trash {
  &:before {
    content: $bti-trash;
  }
}
.bti-twitter {
  &:before {
    content: $bti-twitter;
  }
}
.bti-whatsapp {
  &:before {
    content: $bti-whatsapp;
  }
}
.bti-x {
  &:before {
    content: $bti-x;
  }
}
.bti-youtube {
  &:before {
    content: $bti-youtube;
  }
}
.bti-zero {
  &:before {
    content: $bti-zero;
  }
}
